.container {
  display: grid;
  gap: rem(20);

  @media bp(md) {
    grid-template-columns: repeq(3);
  }
}

.title {
  font-size: rem(24);
  font-weight: 700;

  @media bp(md) {
    font-size: rem(32);
    grid-column: span 3;
  }
}

.item {
  @media bp(sm) {
    background-color: clr(darkable-w);
    box-shadow: shadow(tf, b/15);
    transition: trn(box-shadow, transform);

    &:hover {
      box-shadow: shadow(tg, b/15);
      transform: scale(1.03, 1.03);
    }
  }
}

.lazy {
  min-height: rem(310);

  @media bp(sm) {
    min-height: rem(300);
  }

  @media bp(md) {
    min-height: rem(255);
  }
}

.empty {
  display: none !important;
}
