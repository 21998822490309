.list {
  display: grid;

  @media bp(lg) {
    scroll-behavior: smooth;
    overflow-y: scroll;
    height: rem(1165);
    direction: inherit;

    @mixin scrollHidden;
  }
}

.button {
  margin-bottom: 0;
}
